<template>
  <div class="tjEditor tj-editor">
    <form role="form">
      <!-- text area with Add to note placeholder -->
      <div class="tj-editor__editor">
        <mavon-editor
          rows="6"
          id="note-input"
          placeholder="Add to note..."
          class="tj-editor__text"
          :showShortCut="false"
          v-model="customNote"
          :language="'en'"
          :subfield="false"
          :toolbars="mavonToolbar"
        />
        <div class="tj-editor__notification">
          <span
            class="spinner-border spinner-border-sm ml-2 tj-editor__notification--icon"
            :style="{ opacity: isEddieLoading ? 1 : 0 }"
          ></span>
          <span
            class="tj-editor__notification--title"
            :style="{ opacity: eddieNotification.length > 0 ? 1 : 0 }"
            >Eddie is loading your content</span
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import mavenEditorConfigs from '@/config/mavenEditor';

export default {
  props: {
    onContentChange: {
      type: Function,
      required: false,
    },
    defaultContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customNote: this.defaultContent,
      isLoading: false,
      mavonToolbar: mavenEditorConfigs.mavonToolbar,
      isEddieStarted: false,
      isEddieEnded: false,
      isEddieLoading: false,
      eddieNotification: '',
      eddieQuery: '',
    };
  },
  watch: {
    customNote(value) {
      const text = value.toLowerCase();
      if (text.includes('/eddie')) {
        this.isEddieStarted = true;
        this.eddieNotification = 'Eddie call started';
      }
      if (text.includes('eddie/')) {
        this.isEddieEnded = true;
        this.eddieNotification = 'Eddie has started thinking';
      }

      if (this.isEddieStarted && this.isEddieEnded) {
        const startIndex = text.indexOf('/eddie') + 6;
        const endIndex = text.indexOf('eddie/');
        const queryText = text.slice(startIndex, endIndex).trim();
        this.eddieQuery = queryText;
        this.isEddieLoading = true;
        if (this.isEddieLoading) {
          this.$store.dispatch('EditorAIFetchProcess', this.eddieQuery).then(() => {
            const modifiedInput = this.replaceEddieContentByIndex(value, this.$store.state.editorAIFetch);
            this.customNote = modifiedInput;
            this.isEddieLoading = false;
            this.isEddieStarted = false;
            this.isEddieEnded = false;
            this.eddieNotification = '';
            this.eddieQuery = '';
          });
        }
      } else {
        this.eddieQuery = '';
      }

      if (this.onContentChange) {
        this.onContentChange(value);
      }
    },
  },
  methods: {
    replaceEddieContentByIndex(input, replacementText) {
      const eddieStartIndex = input.indexOf('/eddie');
      const eddieEndIndex = input.indexOf('eddie/');

      if (eddieStartIndex !== -1 && eddieEndIndex !== -1 && eddieEndIndex > eddieStartIndex) {
        const textBeforeEddie = input.slice(0, eddieStartIndex);
        const textAfterEddie = input.slice(eddieEndIndex + 6);

        const modifiedInput = textBeforeEddie + replacementText + textAfterEddie;
        return modifiedInput;
      }
      return input;
    },
  },
};
</script>

<style lang="scss" scoped>
.tj-editor {
  width: 100%;
  height: 100%;
  z-index: 0;

  form {
    width: 100%;
    height: 100%;
  }

  &__editor {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.4rem;
  }
  &__text {
    width: 100%;
    height: 92%;
    box-shadow: none !important;
  }
  &__notification {
    padding: 0.4rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    margin: 0 1rem;
    color: var(--color-grey-1);
    align-items: center;

    &--icon {
      margin-right: 0.5rem;
      margin-left: auto;
    }
  }
  .button-div {
    height: 7%;
  }
}
</style>
